const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

const redirect_uri =
  process.env.NODE_ENV === "production"
    ? "https://" + window.location.hostname
    : "http://localhost:9000";

module.exports = {
  // ports
  serverPort: null,
  //This app url
  appURL: apiBase,
  appName: "MYBE-Business",
  appVersion: 1.4,
  appVersionMessage: "Initilised System",

  //Redirect url for authing
  redirect_uri: redirect_uri,

  //The admin tenants UUID
  adminTenant: "01f3a545-b3de-4adc-b1d5-36d51c177e5b"
};
